import { quest, wallet } from "@fscrypto/domain";
import { useRevalidator } from "@remix-run/react";
import { useOptionalUser } from "~/utils/auth";
import { EcosystemQuestSections } from "./components/eco-quest-section";
import { useEcoOverviewFetcher } from "./components/use-eco-overview";

export function Quests({
  ecosystem,
  quests,
  defaultWallet,
}: {
  ecosystem: string;
  quests: quest.QuestWithUserStateAndReward[];
  defaultWallet?: wallet.Address;
}) {
  const user = useOptionalUser();
  const revalidator = useRevalidator();
  const userId = user?.id;
  const rewardOverviews = useEcoOverviewFetcher([{ ecosystem, agentAddress: defaultWallet?.address, userId }]);
  const ecosystemOverview = rewardOverviews[`${ecosystem}-${defaultWallet?.address}`];
  const questWithRewards: quest.QuestWithUserStateAndReward[] = quests.map((q) => {
    if (!ecosystemOverview || revalidator.state === "loading")
      return { ...q, rewardOverview: undefined, isLoadingRewardOverview: true };

    const rewardOverview = ecosystemOverview.find((ro) => ro.slug === q.quest.slug);
    return { ...q, rewardOverview };
  });

  return (
    <div className="flex w-full flex-col gap-y-12">
      <EcosystemQuestSections quests={questWithRewards} />
    </div>
  );
}
