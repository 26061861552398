import { useEffect, useState } from "react";
import "./score-update-timer.css";

interface ScoreUpdateTimerProps {
  timerHexColor: string;
}

function ScoreUpdateTimer({ timerHexColor }: ScoreUpdateTimerProps) {
  const [timeValue, setTimeValue] = useState<string>("");
  const [borderCompletePercentage, setBorderCompletePercentage] = useState<number>(0);

  useEffect(() => {
    const targetHourUTC = 4; // Set the target hour (4 AM UTC)
    const updateCountdown = () => {
      const now = new Date();
      const nextTargetTime = new Date(now);

      // Determine the next target time
      if (now.getUTCHours() >= targetHourUTC) {
        // If current time is past the target hour, set the target to the same hour on the next day
        nextTargetTime.setUTCDate(now.getUTCDate() + 1);
      }

      nextTargetTime.setUTCHours(targetHourUTC, 0, 0, 0); // Set the time to targetHourUTC:00:00 UTC

      const msLeft = nextTargetTime.getTime() - now.getTime();
      const totalSeconds = 24 * 60 * 60; // Total seconds in a day
      const secondsLeft = msLeft / 1000;
      const percentage = ((totalSeconds - secondsLeft) / totalSeconds) * 100;

      setBorderCompletePercentage(percentage);

      const hours = Math.floor(secondsLeft / 3600);
      const minutes = Math.floor((secondsLeft % 3600) / 60);
      const seconds = Math.floor(secondsLeft % 60);
      setTimeValue(
        `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`,
      );
    };

    // Start the interval to update the countdown every second
    const timer = setInterval(updateCountdown, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="bg-shell relative z-0 rounded-full px-4 py-1" id="score-update-timer">
      <div
        className="absolute inset-0 z-[-1] rounded-full"
        style={{
          top: "-4px",
          right: "-4px",
          bottom: "-4px",
          left: "-4px",
          background: `conic-gradient(${timerHexColor} 0% ${borderCompletePercentage}%, hsl(var(--color-border)) ${borderCompletePercentage}% 100%)`,
        }}
      ></div>
      <div
        className="bg-card absolute inset-0 rounded-full"
        style={{
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
          background: `bg-card`,
        }}
      >
        <p className="text-nowrap z-[20] pt-[0.2rem] text-center" style={{ fontSize: "8px" }}>
          Score update in:
        </p>
        <p className="z-20 text-center text-xs">{timeValue}</p>
      </div>
      <p className="text-nowrap z-[20] text-center" style={{ fontSize: "8px" }}>
        Score update in:
      </p>
      <p className="z-20 text-center text-xs">{timeValue}</p>
    </div>
  );
}

export default ScoreUpdateTimer;
