import { score } from "@fscrypto/domain";
import Highcharts from "highcharts";
import { HighchartsReact } from "highcharts-react-official";
import React from "react";

// Type definitions for incoming props
export interface TimeSeriesChartDataPoint {
  timestamp: Date;
  value: number;
  breakdown: score.SnowflakeScore | null;
}

export interface TimeSeriesChartData {
  backgroundColorHex: string;
  maxPossibleScore?: number;
  datapoints: TimeSeriesChartDataPoint[];
}

export interface TimeSeriesChartProps {
  data: TimeSeriesChartData;
  tooltipHover: [number | null, React.Dispatch<React.SetStateAction<number | null>>];
}

// The TimeSeriesChart component
export const TimeSeriesChart: React.FC<TimeSeriesChartProps> = ({ data, tooltipHover }) => {
  // Map DataPoint[] to the format expected by Highcharts ([time, value])
  const maxScore = data.maxPossibleScore || 21;
  const seriesData = data.datapoints.map((datapoint) => [
    datapoint.timestamp.getTime(), // convert Date to timestamp
    datapoint.value,
  ]);
  const setHoveredIndex = tooltipHover[1];
  const averageY = seriesData.reduce((sum, point) => sum + point[1], 0) / seriesData.length;

  // Find out the y-axis range
  const yAxisMin = 0;

  // Calculate the position of the average on the yAxis (0 is bottom, 1 is top)
  const normalizedPosition = (averageY - yAxisMin) / (maxScore - yAxisMin);
  // Chart options
  const options: Highcharts.Options = {
    chart: {
      type: "areaspline", // Area spline type for the filled area look
      backgroundColor: "hsl(var(--color-background))",
      height: "200px",
      spacing: [0, 0, 0, 0], // No spacing around the chart
      margin: [0, 0, 40, 0], // No margin around the chart
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: {
        day: "%b %e, %y", // e.g., "Feb 3, 24"
      },
      lineColor: "hsl(var(--color-background))",
      tickColor: "hsl(var(--color-muted-foreground))",
      labels: {
        formatter: function () {
          // 'this' context is a reference to the label object where value is the time in milliseconds
          const date = Highcharts.dateFormat("%b %e, %y", this.value as number);
          return date;
        },
        style: {
          color: "hsl(var(--color-muted-foreground))",
          zIndex: 5,
        },
      },
      endOnTick: false,
      startOnTick: false,
      // tickInterval: 7 * 24 * 3600 * 1000, // One tick per every 1 week
      plotBands: [
        {
          from: seriesData[0][0], // Start of the xAxis
          to: seriesData[seriesData.length - 1][0], // End of the xAxis, use the timestamp of the last data point
          color: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 }, // Vertical gradient
            stops: [
              [0, data.backgroundColorHex + "80"], // Start with 50% opacity at the top
              [1 - normalizedPosition, data.backgroundColorHex + "E1"], // Solid color at the average line with 90% opacity
            ],
          },

          zIndex: 0, // Set a zIndex lower than the grid line to not cover them
        },
      ],
      min: seriesData[0][0], // Use the timestamp of the first data point
      max: seriesData[seriesData.length - 1][0],
      lineWidth: 0, // No x-axis line
      tickLength: 6, // No ticks
      // startOnTick: false, // Ensures that the series starts on the tick
      // endOnTick: false, // Ensures that the series ends on the tick
    },
    yAxis: {
      title: {
        text: "",
      },
      tickInterval: 5,
      offset: -55,
      color: "black",
      tickColor: "black",
      zIndex: 5,
      labels: {
        style: {
          color: "hsl(var(--color-foreground))",
          zIndex: 5,
        },
        y: -2, // Move the labels up (-value) or down (+value)
      },
      min: 0,
      maxPadding: 0.4,
      gridLineWidth: 0, // No grid lines
      tickLength: 10,
      minPadding: 10,
    },
    series: [
      {
        name: "Data Series",
        data: seriesData,
        type: "areaspline",
        color: data.backgroundColorHex,
        fillColor: "hsl(var(--color-background))",
        marker: {
          enabled: true,
          fillColor: "hsl(var(--color-card))", // Normal state fill color
          lineWidth: 2,
          lineColor: data.backgroundColorHex, // Marker border color
          states: {
            hover: {
              fillColor: data.backgroundColorHex, // Hover state fill color
            },
          },
        },
      },
    ],
    tooltip: {
      useHTML: true,
      formatter: function () {
        setHoveredIndex(this.point.index);
        const left = this.series.points[this.series.points.length - 1].x === this.point.x;
        return `
          <div style="position: relative; height: 110px;">
            <div style="width: 0.1rem; height: 55px; background-color: ${data.backgroundColorHex};"></div>
            <div style="position: absolute; left: ${left ? -55 : 5}px; top: ${0}px; display: flex; flex-direction: column; align-items: ${left ? "flex-end" : "flex-start"};">
              <div style="font-size: 0.9em; color: hsl(var(--color-foreground));">${this.y}</div>
              <div style="font-size: 0.8em; color: hsl(var(--color-foreground));">${Highcharts.dateFormat("%e %b, %y", (this.x as number) ?? 0)}</div>
            </div>
          </div>
        `;
      },
      backgroundColor: "none", // Disable default background
      borderWidth: 0, // Disable border
      shadow: false, // Disable shadow
      style: {
        pointerEvents: "auto", // Required for tooltip click events if needed
      },
      positioner: function (labelWidth, labelHeight, point) {
        return {
          x: point.plotX - labelWidth / 2,
          y: point.plotY - 60, // Position it above the marker
        };
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        pointStart: Date.UTC(new Date().getFullYear(), 0, 1),
      },
      areaspline: {
        fillOpacity: 0.0,
        cropThreshold: Infinity, // Ensures the area fill extends fully
        pointPlacement: "on", // Aligns points directly on the xAxis to avoid padding
      },
    },
  };

  return (
    <div className="h-full">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default TimeSeriesChart;
