import { Avatar } from "@fscrypto/ui";
import config from "~/utils/config";

export const NetworkAvatarWithText = ({ network }: { network: string }) => {
  return (
    <div className="flex flex-wrap items-center gap-2">
      <Avatar src={config.CLOUDINARY_PROJECT_ICON_PATH + `${network}.svg`} size="sm" />
    </div>
  );
};
