import { cn } from "@fscrypto/ui";
import { VariantProps } from "class-variance-authority";
import { tagVariants } from "~/features/ecosystem/style-variants";

interface PrimaryEcoTagProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof tagVariants> {
  text?: string;
  children?: React.ReactNode;
}

export const PrimaryEcoTag = ({ variant, text, children, ...props }: PrimaryEcoTagProps) => {
  const propsWithoutClassName = { ...props };
  delete propsWithoutClassName.className;
  return (
    <div
      className={cn("flex gap-x-2 rounded px-2 py-1 text-sm", tagVariants({ variant }), props.className)}
      {...propsWithoutClassName}
    >
      {text}
      {children}
    </div>
  );
};
