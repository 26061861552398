import { quest } from "@fscrypto/domain";
import { useEffect, useState } from "react";
import { fetchQuestOverview } from "~/async/fetch-quest-overview";

export const useEcoOverviewFetcher = (deps: { ecosystem: string; agentAddress?: string; userId?: string }[]) => {
  const [cachedResults, setCachedResults] = useState<{ [key: string]: quest.QuestRewardOverview[] }>({});
  const depsToKey = (dep: { ecosystem: string; agentAddress?: string }) => `${dep.ecosystem}-${dep.agentAddress}`;

  useEffect(() => {
    const fetchAndCache = async (dep: { ecosystem: string; userId?: string; agentAddress?: string }) => {
      const key = depsToKey(dep);
      if (cachedResults[key]) {
        return;
      }

      const response = await fetchQuestOverview(dep.ecosystem, { address: dep.agentAddress, userId: dep.userId });

      setCachedResults((prev) => ({
        ...prev,
        [key]: response,
      }));
    };

    deps.forEach((dep) => {
      fetchAndCache(dep);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deps]);

  return cachedResults;
};
