import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@fscrypto/ui";

interface Props {
  prereqs: string[];
  onChange: (prereq: string) => void;
  value?: string;
}

export const PrereqSelector = ({ prereqs, onChange, value }: Props) => {
  const handleChange = (tag: string) => {
    onChange(tag);
  };
  return (
    <Select onValueChange={handleChange} value={value}>
      <SelectTrigger size="md">
        <SelectValue placeholder="Prerequisites" />
      </SelectTrigger>
      <SelectContent className="">
        <SelectItem key="all" value="all">
          All
        </SelectItem>
        {prereqs.map((p) => (
          <SelectItem key={p} value={p}>
            {p}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
