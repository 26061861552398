export const scoreTypes = {
  default: [
    {
      type: "Activity",
      key: "activityScore",
      description:
        "This subscore tracks how active you are on the chain and broadly what kind of transactions you do (eg simple transfers vs. contract interactions)",
    },
    {
      type: "DeFi",
      key: "defiScore",
      description: "Your DeFi score measures things like swaps, lending, and other DeFi activities across the chain",
    },
    {
      type: "Governance",
      key: "govScore",
      description:
        "These metrics measure how active you are in on-chain governance activities like staking (including liquid staking) and voting",
    },
    {
      type: "NFTs",
      key: "nftsScore",
      description: "This subscore tracks how many NFTs you've bought and sold and how many mints you've done",
    },
    {
      type: "Tokens",
      key: "tokensScore",
      description:
        "These metrics measure your net accumulation of tokens via transfers, centralized exchanges, and bridges",
    },
  ],
};
