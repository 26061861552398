import { tax } from "@fscrypto/domain";
import { Button, Card, CardContent, LinkArrow, Tag, Text } from "@fscrypto/ui";
import { Link } from "@remix-run/react";

interface Props {
  taxStatus: tax.TaxDocumentationStatus;
}

export const Taxes = ({ taxStatus }: Props) => {
  return (
    <Card className="dark:bg-gray-90 shadow">
      <CardContent>
        <div className="flex flex-col justify-between sm:flex-row sm:items-center">
          <div className="flex flex-col gap-y-3">
            <div className="flex flex-row flex-wrap items-center gap-x-2">
              <Text className="text-contrast whitespace-nowrap" size="lg">
                Tax Summary
              </Text>
              {taxStatus === "NOT_STARTED" && (
                <Tag variant="filled" size="sm" color="warning" className="ml-2">
                  Unverified
                </Tag>
              )}
              {taxStatus === "VALID" && (
                <Tag variant="filled" size="sm" color="success" className="ml-2">
                  Verified
                </Tag>
              )}
              {taxStatus === "PENDING" && (
                <Tag variant="filled" size="sm" color="warning" className="ml-2">
                  Pending
                </Tag>
              )}
              {taxStatus === "INVALID" && (
                <Tag variant="filled" size="sm" color="error" className="ml-2">
                  Invalid
                </Tag>
              )}
            </div>
            <div>
              <Text size="sm">
                Flipside is required to collect information for participants who earn $600+ in USD value.
              </Text>
            </div>
            <LinkArrow variant="alternate">
              <a href="https://docs.flipsidecrypto.xyz/earn/tax-center" target="_blank" rel="noreferrer">
                Learn More
              </a>
            </LinkArrow>
          </div>
          <div className="pt-6 sm:pt-0 md:px-4">
            {taxStatus === "NOT_STARTED" && (
              <Button className="w-full" size="sm" variant="outline" asChild>
                <Link to="/tax/taxbit">Verify Tax Info</Link>
              </Button>
            )}
            {(taxStatus === "VALID" || taxStatus === "INVALID") && (
              <Button className="w-full" size="sm" variant="outline" asChild>
                <Link to="/tax/taxbit">Edit Tax Info</Link>
              </Button>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
