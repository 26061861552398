import { rewards as rewardsDomain } from "@fscrypto/domain";
import { Card, CardContent, MoneyBagIcon, Text } from "@fscrypto/ui";
import { Skeleton } from "@fscrypto/ui/skeleton";
import { BanknoteIcon } from "lucide-react";
import { roundRewardsAmount } from "~/utils/helpers";
import { useRewards } from "../state/use-rewards";

const TotalValueEarnedCardWrapped = ({
  loading,
  lifetimeUsdTotal,
}: {
  loading?: boolean;
  lifetimeUsdTotal: number;
}) => {
  return (
    <Card className="dark:bg-gray-90 shadow">
      <CardContent className="flex h-full flex-col gap-y-5">
        <span className="flex items-center gap-x-2">
          <div className="bg-green-70 dark:bg-green-20 rounded-full p-1 text-white dark:text-black">
            <BanknoteIcon className="size-4 h-5 w-5" />
          </div>
          <Text className="text-contrast" size="sm">
            Total Value Earned
          </Text>
        </span>
        {loading ? (
          <Skeleton className="h-8 w-40" />
        ) : (
          <Text className="text-contrast" size="3xl">
            ${roundRewardsAmount(lifetimeUsdTotal)} USD
          </Text>
        )}
      </CardContent>
    </Card>
  );
};

const PendingRewardsCardWrapped = ({ loading, pendingAmount }: { loading?: boolean; pendingAmount: number }) => {
  return (
    <Card className="dark:bg-gray-90 shadow">
      <CardContent className="flex h-full">
        <div className="flex w-full flex-col justify-between sm:flex-row">
          <div className="flex flex-col gap-y-5">
            <span className="flex items-center gap-x-2">
              <div className="bg-orange-60 dark:bg-orange-20 rounded-full p-1 text-white dark:text-black">
                <MoneyBagIcon className="h-5 w-5" />
              </div>
              <Text className="text-contrast" size="sm">
                Pending Rewards
              </Text>
            </span>
            <div className="flex justify-between">
              {loading ? (
                <Skeleton className="h-8 w-40" />
              ) : (
                <Text size="3xl" className="text-contrast">
                  ${roundRewardsAmount(pendingAmount)} USD
                </Text>
              )}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export const PendingRewardsCard = () => {
  const rewards = useRewards();

  if (!rewards.rewardOverview?.userRewards) return <PendingRewardsCardWrapped loading pendingAmount={0} />;

  const claimableRewards = rewards.rewardOverview.userRewards.filter((r) => !rewardsDomain.RewardsService.isClaimed(r));
  const claimableTotal = claimableRewards.reduce((acc, r) => acc + r.usdClaimable, 0);

  return <PendingRewardsCardWrapped pendingAmount={claimableTotal} />;
};

export const TotalValueEarnedCard = () => {
  const rewards = useRewards();

  if (!rewards.rewardOverview?.rewardTotals) return <TotalValueEarnedCardWrapped loading lifetimeUsdTotal={0} />;

  return <TotalValueEarnedCardWrapped lifetimeUsdTotal={rewards.rewardOverview?.rewardTotals.lifetimeUsdTotal} />;
};
