import { quest } from "@fscrypto/domain";
import { Spinner, Text } from "@fscrypto/ui";
import { cn } from "@fscrypto/ui";
import { VariantProps } from "class-variance-authority";
import { CheckIcon, XIcon } from "lucide-react";
import { stepProgressVariants } from "~/features/ecosystem/style-variants";

interface Props {
  ecosystem?: quest.QuestEcosystem;
  currentStep: number;
  totalSteps: number;
  status: quest.QuestUserStatus | null;
  loading: boolean;
}

export const QuestStepProgress = ({ ecosystem, currentStep, totalSteps, status, loading }: Props) => {
  const getIconVariant = () => {
    if (status === "COMPLETED" || status === "CLAIMED") {
      return ecosystem;
    }
    if (status === "FAILED") {
      return "warning";
    }
    if (loading) {
      return "loading";
    }
    return "outlined";
  };
  const getStepVariant = (step?: number) => {
    if (status === "COMPLETED" || status === "CLAIMED") {
      return ecosystem;
    }
    if (step !== undefined && step < currentStep) {
      return "secondary";
    }
    return "primary";
  };
  const getIcon = () => {
    if (loading) return <Spinner size="md" />;
    if (status === "FAILED") return <XIcon className="h-4 w-4" />;
    return <CheckIcon className="h-4 w-4" />;
  };

  const getStepText = () => {
    if (status === "CLAIMED") return "Reward Claimed";
    if (status === "COMPLETED") return "Quest Completed";
    if (status === "FAILED") return "Verification Failed";
    if (status === "VALIDATING") return "Verifying...";
    return `${currentStep}/${totalSteps} steps complete`;
  };

  const isStepActive = (thisStep: number) => {
    if (status && status !== "STARTED") return true;
    return thisStep < totalSteps;
  };

  return (
    <div className="flex flex-row items-center gap-x-3">
      <div className="flex flex-col items-end gap-y-1">
        <Text size="sm" className="dark:text-gray-40 text-gray-50">
          {getStepText()}
        </Text>
        <div className={"flex flex-row gap-x-1"}>
          {Array.from({ length: totalSteps }, (_, i) => (
            <EcoStep key={i} active={isStepActive(i)} variant={getStepVariant(i)} />
          ))}
        </div>
      </div>
      <div
        className={cn(
          "text-shell flex h-6 w-6 items-center justify-center rounded-full",
          stepProgressVariants({ variant: getIconVariant() }),
        )}
      >
        {getIcon()}
      </div>
    </div>
  );
};

interface EcoStepProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof stepProgressVariants> {
  active: boolean;
}

export const EcoStep = ({ variant, active, ...props }: EcoStepProps) => {
  const ecosystem = active ? variant : "primary";
  return <div className={cn("h-1 w-7 rounded-full", stepProgressVariants({ variant: ecosystem }))} {...props} />;
};
