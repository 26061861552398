import { quest } from "@fscrypto/domain";
import { GET } from "./fetch";

export const fetchQuestOverview = async (ecosystem: string, identifier?: { address?: string; userId?: string }) => {
  const params = new URLSearchParams();

  if (identifier?.address) {
    params.append("address", identifier.address);
  }
  if (identifier?.userId) {
    params.append("userId", identifier.userId);
  }

  const queryString = params.toString();

  return GET<quest.QuestRewardOverview[]>(
    `/api/quests/ecosystem/${ecosystem}/overview${queryString ? `?${queryString}` : ""}`,
  );
};
