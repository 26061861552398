import { quest } from "@fscrypto/domain";
import { useSearchParams } from "@remix-run/react";
import { useMemo, useState } from "react";
import { EcoQuestCard } from "./eco-quest-card";
import { PrereqSelector } from "./prereq-selector";

// This is the version of the quest cards that have the score buckets
export const EcoQuestSection = ({ quests }: { quests: quest.QuestWithUserStateAndReward[] }) => {
  return (
    <div className="min-h-40 grid grid-cols-1 gap-6 md:grid-cols-1 lg:grid-cols-2">
      {quests.map((quest) => (
        <EcoQuestCard
          key={quest.quest.slug}
          ecoQuest={quest}
          ecosystem={quest.quest.project.name as quest.QuestEcosystem}
        />
      ))}
    </div>
  );
};

const questCategories = quest.questCategoryTypes._def.values;
export const EcosystemQuestSections = ({ quests }: { quests: quest.QuestWithUserStateAndReward[] }) => {
  const [params] = useSearchParams();
  // example: ?prereq=Grail%20S1%20Participant
  const searchParam = params.get("prereq");
  const [prereqsFilter, setPrereqsFilter] = useState<string>(searchParam ?? "all");
  const prereqs = useMemo(() => findUniquePrerequisites(quests), [quests]);

  const onTagChange = (prereq: string) => {
    setPrereqsFilter(prereq);
  };

  return (
    <div className="flex w-full flex-col gap-y-12">
      {questCategories.map((category, idx) => {
        const categoryQuests = quests.filter((q) => {
          const isInCategory = q.quest.category === category;
          const hasPrereqFilter =
            !prereqsFilter || prereqsFilter === "all" || q.quest.prerequisites.some((p) => p.label === prereqsFilter);
          return isInCategory && hasPrereqFilter;
        });
        if (categoryQuests.length === 0) return null;
        return (
          <div key={category} className="flex flex-col gap-y-3">
            <div className="flex flex-row items-center justify-between">
              <h3 className="text-contrast text-xl">{category}</h3>
              {prereqs.length > 0 && idx === 0 && (
                <PrereqSelector value={prereqsFilter} onChange={onTagChange} prereqs={prereqs.map((p) => p.label)} />
              )}
            </div>
            <EcoQuestSection quests={categoryQuests} />
          </div>
        );
      })}
    </div>
  );
};

const findUniquePrerequisites = (quests: quest.QuestWithUserStateAndReward[]) => {
  const seenTags = new Set<string>();
  const prereqs = [];
  for (const q of quests) {
    for (const p of q.quest.prerequisites) {
      if (!seenTags.has(p.label)) {
        seenTags.add(p.label);
        prereqs.push(p);
      }
    }
  }
  return prereqs;
};
