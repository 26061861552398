import { quest, score } from "@fscrypto/domain";
import { noScoreEcosystems } from "@fscrypto/domain/quest";
import { Button } from "@fscrypto/ui";
import { Link, useRevalidator, useSubmit } from "@remix-run/react";
import clsx from "clsx";
import { useState } from "react";
import { $path } from "remix-routes";
import { useAddWalletModal } from "~/features/wallet-management/state/use-add-wallet-modal";
import { useOptionalUser } from "~/utils/auth";
import { useScoreChart } from "../use-score-chart";
import { NetworkAvatarWithText } from "./avatar-with-text";
import ScoreChartHeader from "./score-chart-header";
import ScoreMeter from "./score-meter";
import { scoreTypes } from "./score-subcategories";
import { TimeSeriesChart } from "./timeseries-chart";

export function UnauthenticatedScoreChartContainer({ ecosystem }: { ecosystem: quest.QuestEcosystem }) {
  const submit = useSubmit();
  return (
    <div className="relative flex items-center justify-center">
      <Button
        variant="primary"
        size="lg"
        className="absolute z-10 flex items-center justify-center"
        onClick={() => {
          submit(null, {
            method: "post",
            action: $path("/auth/auth0", {
              redirectUrl: $path("/earn/:ecosystem", { ecosystem: ecosystem }),
            }),
          });
        }}
      >
        Connect wallet
      </Button>
      <ScoreChartContainer ecosystem={ecosystem} blur />
    </div>
  );
}

export function NoWalletFoundScoreChartContainer({ ecosystem }: { ecosystem: quest.QuestEcosystem }) {
  const addWalletModalState = useAddWalletModal();
  const isComingSoon = noScoreEcosystems.includes(ecosystem);
  return (
    <div className="relative flex items-center justify-center">
      <Button
        variant={isComingSoon ? "special" : "primary"}
        size="lg"
        disabled={isComingSoon}
        className="absolute z-10 flex items-center justify-center"
        onClick={() => {
          addWalletModalState?.setModalOpen(ecosystem, true);
        }}
      >
        {isComingSoon ? "Coming soon" : "Connect Wallet"}
      </Button>
      <ScoreChartContainer ecosystem={ecosystem} blur />
    </div>
  );
}

export function ScoreChartContainer({
  ecosystem,
  scores,
  blur,
}: {
  ecosystem: quest.QuestEcosystem;
  scores?: score.SnowflakeScore[];
  blur?: boolean;
}) {
  const revalidator = useRevalidator();
  const chartData = useScoreChart({ scores, ecosystem });
  const getSubscoresFromIndex = (index: number) => {
    if (chartData.datapoints[index]?.breakdown) {
      return chartData.datapoints[index]?.breakdown;
    } else {
      return chartData.datapoints[chartData.datapoints.length - 1]?.breakdown;
    }
  };
  const currentScore = chartData.datapoints[chartData.datapoints.length - 1]?.value;
  const user = useOptionalUser();
  const chartColor = chartData.backgroundColorHex;
  const mostRecentScore = chartData.datapoints[chartData.datapoints.length - 1]?.value || 0;
  const scoreSubcategories = scoreTypes.default;

  const [hoveredSubcategory, setHoveredSubcategory] = useState<string | null>(scoreSubcategories[0].type);
  const [hoveredChartIndex, setHoveredChartIndex] = useState<number | null>(
    chartData.datapoints.length ? chartData.datapoints.length - 1 : 0,
  );
  const ecosystemLabel = ecosystem[0].toUpperCase() + ecosystem.slice(1);
  const selectedSubscores =
    hoveredChartIndex !== null
      ? getSubscoresFromIndex(hoveredChartIndex)
      : getSubscoresFromIndex(chartData.datapoints.length - 1);

  return (
    <div
      className={clsx("relative rounded-md border pb-8", {
        "pointer-events-none blur-md": revalidator.state === "loading",
        "pointer-events-none blur-sm grayscale": blur,
      })}
    >
      <div
        style={{
          height: "200px",
          width: "100%",
          background: `linear-gradient(to top, ${chartColor}80, ${chartColor}00)`,
        }}
        className="flex flex-col overflow-hidden px-3 pt-3 md:px-6 md:pt-6"
        id="header"
      >
        {!blur && <ScoreChartHeader hexColor={chartColor} />}
        <div className={`mb-[-5rem] h-[100%]`}>
          <ScoreMeter value={mostRecentScore} color={chartColor} textSize="text-5xl" />
        </div>
        <div className="z-10 mt-4 flex items-center justify-center gap-2">
          <NetworkAvatarWithText network={ecosystem} />
          <h3 className="text-foreground text-sm">{ecosystemLabel} Score</h3>
        </div>
        {!user ? (
          <div className="relative flex items-center justify-center">
            <div className="text-foreground absolute top-6 z-10 flex flex-col items-center gap-4 overflow-visible text-sm">
              <p className="">Login to your Flipside account to see scores</p>

              <Link to="/auth/auth0" className="">
                <Button className="bg-card text-foreground hover:bg-shell">Login</Button>
              </Link>
            </div>
          </div>
        ) : null}
      </div>
      <div className="m-0 w-full overflow-hidden p-0" id="score-areaspline">
        {chartData.datapoints.length > 0 && currentScore > 0 && (
          <TimeSeriesChart data={chartData} tooltipHover={[hoveredChartIndex, setHoveredChartIndex]} />
        )}
      </div>
      <div className="mt-8 w-full px-4 md:px-12">
        <div className="flex flex-wrap items-center justify-center gap-6">
          {scoreSubcategories.map((subcategory) => (
            <div
              className={`xs:min-w-[140px] flex min-w-[90px] cursor-pointer flex-col items-center justify-center gap-2 rounded-md border py-4`}
              key={subcategory.type}
              style={{
                // minWidth: `${subcategoryMinWidth}px`,
                borderColor: hoveredSubcategory === subcategory.type ? chartColor : "",
              }}
              onMouseEnter={() => setHoveredSubcategory(subcategory.type)}
            >
              <h2 className="text-3xl">{selectedSubscores ? (selectedSubscores as any)[subcategory.key] : 0}/3</h2>
              <p className="text-sm">{subcategory.type}</p>
            </div>
          ))}
        </div>
        <div className="min-h-28 mt-8 flex flex-col items-start gap-2">
          <h3 className="text-xl font-semibold">{hoveredSubcategory}</h3>
          <p className="text-sm">{scoreSubcategories.filter((s) => s.type === hoveredSubcategory)[0].description}</p>
        </div>
      </div>
    </div>
  );
}
