import { quest } from "@fscrypto/domain";
import { QuestEcosystem, questEcosystems } from "@fscrypto/domain/quest";
import { ArrowRight } from "lucide-react";
import { useRewardsDrawer } from "~/features/earn/state/use-reward-drawer";
import { PrimaryEcoTag } from "~/features/quests/components/eco-tag-primary";

export function UnauthenticatedEcosystemReward({ ecosystem }: { ecosystem: quest.QuestEcosystem }) {
  return <TotalReward currency="USD" ecosystem={ecosystem} />;
}

export function AuthenticatedEcosystemReward({
  totalValue,
  pendingValue,
  currency,
  ecosystem,
}: {
  totalValue: number;
  pendingValue: number;
  currency: string;
  ecosystem: quest.QuestEcosystem;
}) {
  const rewardDrawer = useRewardsDrawer();
  const pendingValueRounded = pendingValue.toFixed(2);

  return (
    <>
      <TotalReward totalValue={totalValue} currency={currency} ecosystem={ecosystem} />
      <div className="flex flex-col items-center justify-between gap-x-5 gap-y-2 text-center">
        <div className="outline-muted-foreground/20 rounded-full p-2 px-4 outline">
          <p className="text-lg font-medium">
            {pendingValueRounded} {currency}
          </p>
        </div>
      </div>
      <button
        className="dark:text-gray-15 text-muted-foreground mx-auto flex items-center gap-1 py-2 hover:underline"
        onClick={() => rewardDrawer.setOpen(true)}
      >
        <p className="text-foreground text-sm">Pending Rewards</p>
        <ArrowRight className="h-3 w-3" />
      </button>
    </>
  );
}

export function TotalReward({
  totalValue,
  currency,
  ecosystem,
}: {
  totalValue?: number;
  currency: string;
  ecosystem: quest.QuestEcosystem;
}) {
  const totalValueRounded = totalValue !== undefined ? Math.round(parseFloat(totalValue.toFixed(2)) * 100) / 100 : "--";
  const variantString = questEcosystems.includes(ecosystem) ? ecosystem : "primary";

  return (
    <div className="flex flex-col gap-y-4 py-3">
      <div className="flex flex-col items-center justify-between gap-x-5 gap-y-2 text-center">
        <PrimaryEcoTag className="rounded-full p-2 px-4" variant={variantString as QuestEcosystem | "primary"}>
          <p className="text-xl font-medium md:text-2xl">
            {totalValueRounded} {currency}
          </p>
        </PrimaryEcoTag>
        <p className="text-muted-foreground text-sm">Total Value Earned</p>
      </div>
    </div>
  );
}
