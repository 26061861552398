import ScoreUpdateTimer from "./score-update-timer";

function ScoreChartHeader({ hexColor }: { hexColor: string }) {
  return (
    <div className="min-h-10 flex items-center justify-between" id="score-chart-container-header">
      <ScoreUpdateTimer timerHexColor={hexColor} />
    </div>
  );
}

export default ScoreChartHeader;
